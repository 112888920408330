import { Component, Input, OnInit } from '@angular/core';
import { Arguments } from './app.component'
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'verify-email',
  templateUrl: './verify-email.component.html',
})
export class VerifyEmailComponent implements OnInit {

  @Input() data: Arguments;

  error = false;
  loading = true;

  constructor(
    private auth: AngularFireAuth,
    private db: AngularFirestore
  ) {}

  ngOnInit() {
    this.auth.applyActionCode(this.data.actionCode).then(() => {
      if (this.data.continueUrl) {
        const uid = this.getParameterByName('uid', this.data.continueUrl);
        if (uid) {
          const ref = this.db.collection('verifications').doc(uid);
          ref.set({emailVerified: true}).then(() => {
            console.log('Success');
          }).catch((error) => {
            console.log('Failed to write verifications', error);
          });
        }
      }
      this.error = false;
      this.loading = false;
    }).catch((error) => {
      console.log('FAILED TO CONFIRM EMAIL', error);
      this.error = true;
      this.loading = false;
    });
  }

  getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
