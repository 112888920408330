import { Component, Input, OnInit } from '@angular/core';
import { Arguments } from './app.component'

@Component({
  selector: 'event-invite',
  templateUrl: './invite.component.html',
})
export class InviteComponent implements OnInit {

  @Input() data: Arguments;

  constructor() {}

  ngOnInit() {}

}
