import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { map, tap, delay, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

export class Arguments {
  mode: string;
  actionCode: string;
  apiKey: string;
  continueUrl: string;
  lang: string;

  constructor(
    mode: string,
    actionCode: string,
    apiKey: string,
    continueUrl: string,
    lang: string
  ) {
    this.mode = mode;
    this.actionCode = actionCode;
    this.apiKey = apiKey;
    this.continueUrl = continueUrl;
    this.lang = lang;
  }
}

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'www-user-agorify-com';

  public args: Observable<Arguments>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AngularFireAuth,
    @Inject(DOCUMENT) private document: any,
  ) {}

  ngOnInit() {
    console.log('auth', this.auth);

    this.args = this.route
      .queryParamMap
      .pipe(
        map((params: Params) => {
          return new Arguments(
            params.get('mode'),
            params.get('oobCode') || '',
            params.get('apiKey'),
            params.get('continueUrl'),
            params.get('lang') || 'en'
          );
        }),
        switchMap((args: Arguments) => {
          if (args.mode !== 'resetPassword' && args.mode !== 'recoverEmail' && args.mode !== 'verifyEmail' && args.mode !== 'eventInvite') {
            this.document.location.href = 'https://agorify.com'
            return of(args).pipe(delay(5000));
          }
          return of(args);
        })
      );
  }
}
