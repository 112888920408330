import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Arguments } from './app.component'
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {

  @Input() data: Arguments;

  public form: FormGroup;
  public hide = true;
  public email = null;
  public loading = true;
  public fatalError = false;
  public success = false;

  constructor(
    private auth: AngularFireAuth,
    private fb: FormBuilder,
  ) {
    this.form = fb.group({
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.auth.verifyPasswordResetCode(this.data.actionCode).then((email) => {
      this.loading = false;
      this.email = email;
    }).catch((error) => {
      this.loading = false;
      this.fatalError = true;
    });
  }

  onSubmit() {
    this.loading = true;
    const newPassword = this.form.value.password;
    console.log('new password', newPassword);
    this.auth.confirmPasswordReset(this.data.actionCode, newPassword).then((resp) => {
      this.loading = false;
      this.success = true;
    }).catch((error) => {
      this.success = false;
      this.loading = false;
      // TODO: error handling
      console.log('could not change password', error);
    });
  }

}
